import StarFilled from 'assets/Icons/StarFilled'
import { Button } from 'components/common'
import { addToast, metaApi } from 'components/features/Other/api'
import { useAppDispatch } from 'hooks'
import { t } from 'i18next'
import { Star } from 'react-feather'
import { useParams } from 'react-router-dom'

import { documentApi } from '../../api/documentsApi'

interface Props {
  className?: string
  styleInverted?: boolean
}

const FavoriteButton = ({
  className = '',
  styleInverted = false,
}: Props): JSX.Element | null => {
  const { documentId } = useParams()
  const dispatch = useAppDispatch()

  const httpGetDocument = documentApi.useGetDocumentByIdQuery(
    documentId ?? '',
    {
      skip: !documentId,
    }
  )

  const [httpMarkFavorite, httpMarkFavoriteState] =
    metaApi.useAddStarredItemMutation()
  const [httpUnmarkFavorite, httpUnmarkFavoriteState] =
    metaApi.useDeleteStarredItemsMutation()

  const handleClick = () => {
    if (!httpGetDocument.isSuccess || !documentId) return

    const { starred_item_id: starId } = httpGetDocument.data.data

    const entityType = 'document'
    const refetchFn = httpGetDocument.refetch

    try {
      if (starId) {
        httpUnmarkFavorite(documentId)
        dispatch(
          addToast({
            color: 'success',
            content: t('document.favoriteRemoved'),
          })
        )
      } else {
        httpMarkFavorite({ type: entityType, id: documentId })

        dispatch(
          addToast({
            color: 'success',
            content: t('document.favoriteAdded'),
          })
        )
      }
    } catch (error) {
      dispatch(
        addToast({
          color: 'error',
          content: t('error.general'),
        })
      )
    } finally {
      refetchFn()
    }
  }

  if (!httpGetDocument.isSuccess) return null

  const { starred_item_id: starId } = httpGetDocument.data.data

  return (
    <Button
      icon={!!starId ? <StarFilled /> : <Star />}
      variant={styleInverted ? 'secondary' : 'primary'}
      color={styleInverted ? 'dark' : 'primary'}
      size="x-small"
      onClick={handleClick}
      className={className}
      disabled={
        httpMarkFavoriteState.isLoading ||
        httpUnmarkFavoriteState.isLoading ||
        httpGetDocument.isFetching
      }
    >
      {!!starId ? t('document.unmarkFavorite') : t('document.markFavorite')}
    </Button>
  )
}

export default FavoriteButton
