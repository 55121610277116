import { FileCheckIcon } from 'assets/Icons'
import {
  DefaultLayout,
  LoadingIndicator,
  PageTitle,
  Section,
  SectionHeader,
  Typography,
} from 'components/common'
import { userApi } from 'components/features/User/api'
import { t } from 'i18next'
import { Box, File, Clock } from 'react-feather'

import styles from './OverviewPage.module.scss'
import { DashboardHeroCard, LatestFeedbackTable } from '../../components'
import ApprovalsTable from '../../components/ApprovalsTable/ApprovalsTable'

const OverviewPage = (): JSX.Element => {
  const dashboardApi = userApi.useGetDashboardInfoQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  return (
    <DefaultLayout
      bodyProps={{
        className: styles.body,
      }}
    >
      {dashboardApi.isLoading && (
        <div className={styles.loading}>
          <LoadingIndicator />
        </div>
      )}
      {dashboardApi.isError && (
        <Typography element="h1" type="display" size="medium" fontWeight="bold">
          {t('overview.loadFail')}
        </Typography>
      )}
      {dashboardApi.isSuccess && (
        <>
          <PageTitle title={t('overview.title')} divider />
          <Section className={styles.heroCards}>
            <DashboardHeroCard
              icon={<Box />}
              title={String(dashboardApi.data.data.number_of_practices)}
              subtitle={t('overview.cardPractices')}
              iconColor="primary"
            />
            <DashboardHeroCard
              icon={<File />}
              title={String(dashboardApi.data.data.number_of_documents)}
              subtitle={t('overview.cardDocuments')}
              iconColor="secondary"
            />
            <DashboardHeroCard
              icon={<FileCheckIcon />}
              title={String(
                dashboardApi.data.data.number_of_open_approvals_on_practice
              )}
              subtitle={t('overview.cardApproval')}
              iconColor="tertiary"
            />
          </Section>

          {dashboardApi.data.data.due_guidelines && (
            <Section className={styles.heroCards}>
              <DashboardHeroCard
                icon={<Clock />}
                title={`${dashboardApi.data.data.due_guidelines.three_months}`}
                subtitle={t('overview.monthsDueGuidelines', { months: 3 })}
                iconColor="accent"
              />
              <DashboardHeroCard
                icon={<Clock />}
                title={`${dashboardApi.data.data.due_guidelines.six_months}`}
                subtitle={t('overview.monthsDueGuidelines', { months: 6 })}
                iconColor="accent"
              />
            </Section>
          )}

          {/*<Section>*/}
          {/*  <div className={styles.sectionTitle}>*/}
          {/*    <Typography type="display" size="x-small">*/}
          {/*      {t('overview.chartTitle')}*/}
          {/*    </Typography>*/}
          {/*  </div>*/}
          {/*  <Card>*/}
          {/*    <Card.Body>*/}
          {/*      <ReviewPendingChart*/}
          {/*        data={dashboardApi.data.data.number_of_practices_to_review}*/}
          {/*      />*/}
          {/*    </Card.Body>*/}
          {/*  </Card>*/}
          {/*</Section>*/}
          {dashboardApi.data.data.approvals.length > 0 && (
            <Section>
              <div className={styles.sectionTitle}>
                <SectionHeader title={t('overview.approvalsTitle')} />
              </div>

              <ApprovalsTable data={dashboardApi.data.data.approvals} />
            </Section>
          )}
          <Section>
            <div className={styles.sectionTitle}>
              <SectionHeader title={t('overview.feedbackTitle')} />
            </div>

            <LatestFeedbackTable data={dashboardApi.data.data.feedbacks} />
          </Section>
        </>
      )}
    </DefaultLayout>
  )
}

export default OverviewPage
