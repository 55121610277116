import BrantnerLogo from './Logos/BrantnerLogo'
import MedPlanLogo from './Logos/MedPlanLogo'
import PVJosefstadtLogo from './Logos/PVJosefstadtLogo'
import RaiffeisenLogo from './Logos/RaiffeisenLogo'
import { LogoProps } from './Logos/types'
import WienEnergieLogo from './Logos/WienEnergieLogo'
import { CLIENT_DATA } from './temp-constants'

const ClientLogo = ({
  collapsed = false,
  clientId,
}: { clientId: string } & LogoProps) => {
  switch (clientId) {
    case CLIENT_DATA.id_br:
      return <BrantnerLogo collapsed={collapsed} />
    case CLIENT_DATA.id_ra:
      return <RaiffeisenLogo collapsed={collapsed} />
    case CLIENT_DATA.id_mp:
      return <MedPlanLogo collapsed={collapsed} />
    case CLIENT_DATA.id_pv:
      return <PVJosefstadtLogo />
    case CLIENT_DATA.id_we:
      return <WienEnergieLogo collapsed={collapsed} />
    default:
      return <></>
  }
}

export default ClientLogo
