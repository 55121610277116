import { Button } from 'components/common'
import { t } from 'i18next'
import { Plus } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'

const UploadDocumentButton = ({
  styleInverted = false,
}: {
  styleInverted?: boolean
}): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClick = () => {
    navigate({
      ...location,
      pathname: 'upload',
    })
  }

  return (
    <Button
      variant={styleInverted ? 'secondary' : 'primary'}
      size="small"
      icon={<Plus />}
      onClick={handleClick}
    >
      {t('practices.upload')}
    </Button>
  )
}

export default UploadDocumentButton
