import clsx from 'clsx'
import { useId } from 'hooks'
import { ChevronRight } from 'react-feather'

import Breadcrumb from './Breadcrumb'
import { BreadcrumbsProps } from './Breadcrumb.type'
import styles from './Breadcrumbs.module.scss'
import IconContainer from '../IconContainer/IconContainer'

const Breadcrumbs = ({
  crumbs,
  className,
  styleInverted,
}: BreadcrumbsProps) => {
  const css = clsx(styles.root, className)
  const id = useId()

  return (
    <div className={css}>
      {crumbs.map((crumbProps, index) => (
        <div key={id + crumbProps.title} className={styles.crumb}>
          <Breadcrumb current={index === crumbs.length - 1} {...crumbProps} />
          {index !== crumbs.length - 1 && (
            <IconContainer
              className={clsx(styles.icon, {
                [styles.inverted]: styleInverted,
              })}
              size="x-small"
              icon={<ChevronRight />}
            />
          )}
        </div>
      ))}
    </div>
  )
}

Breadcrumbs.Crumb = Breadcrumb

export default Breadcrumbs
