import clsx from 'clsx'
import { Location } from 'react-router-dom'

import styles from './DetailsHeader.module.scss'
import { useFrontOfficeCheck } from '../../../../../hooks'

const DetailsHeader: React.FC<{ backLink: Location | string }> = ({
  children,
}): JSX.Element => {
  // const navigate = useNavigate()
  const isFrontOffice = useFrontOfficeCheck()

  const classes = clsx(styles.root, {
    [styles.inverted]: !isFrontOffice,
  })
  return (
    <header className={classes}>
      {/*<Button*/}
      {/*  icon={<ArrowLeft />}*/}
      {/*  variant="text"*/}
      {/*  size="small"*/}
      {/*  aria-label="Back"*/}
      {/*  onClick={() => navigate(-1)}*/}
      {/*/>*/}
      {children}
    </header>
  )
}

export default DetailsHeader
