import clsx from 'clsx'
import { Link } from 'react-router-dom'

import styles from './Breadcrumb.module.scss'
import { BreadcrumbProps } from './Breadcrumb.type'
import IconContainer from '../IconContainer/IconContainer'
import Tooltip from '../Tooltip/Tooltip'
import Typography from '../Typography/Typography'

const Breadcrumb: React.VFC<BreadcrumbProps> = ({
  current = false,
  iconOnly = false,
  href = '',
  title,
  icon,
  tag,
  tooltip,
  styleInverted = false,
}) => {
  const css = clsx(
    styles.root,
    {
      [styles.current]: current,
      [styles.iconOnly]: iconOnly,
    },
    'crumb'
  )

  return (
    <TooltipWrapper content={tooltip}>
      <div className={css}>
        <Link
          to={href}
          replace={current}
          className={clsx(styles.link, { [styles.inverted]: styleInverted })}
        >
          {icon && (
            <IconContainer
              size="x-small"
              aria-label={iconOnly ? title : ''}
              className={clsx(styles.icon, {
                [styles.inverted]: styleInverted,
              })}
              icon={icon}
            />
          )}
          {title && (
            <Typography
              size="small"
              className={clsx(styles.title, {
                [styles.inverted]: styleInverted,
              })}
            >
              {title}
            </Typography>
          )}
        </Link>
        {tag && <span className={styles.tag}>{tag}</span>}
      </div>
    </TooltipWrapper>
  )
}

const TooltipWrapper = ({
  content,
  children,
}: {
  children: React.ReactNode
  content?: string | null
}): JSX.Element => {
  if (content) {
    return (
      <Tooltip size="x-small" position="bottom" color="dark" content={content}>
        {children}
      </Tooltip>
    )
  }

  return <>{children}</>
}

export default Breadcrumb
